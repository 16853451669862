<template>
  <vx-card no-shadow>
    <vs-list>
      <vs-list-header title="Products in this category">
      </vs-list-header>
      <vs-list-item icon-pack="feather" v-for="product in products" :key="product.id"
       icon="icon-check" :title="product.name" :subtitle="'Price: $'+ product.price">
        <vs-button radius color="dark" class="inline" type="line" @click="$router.push({path: `/products/${product.id}`})"
         icon-pack="feather" icon="icon-eye"></vs-button>
      </vs-list-item>
    </vs-list>
  </vx-card>
</template>

<script>

export default{
  computed: {
    products(){
      return this.$store.state.categoryList.category.products
    },
  }
}
</script>
