
<template>
  <div id="faq-page">
    <div v-if="!isLoading">
      <div class="vx-row">
        <div class="vx-col w-full mt-2 md:mt-4">
          <template lang="html">
            <vs-tabs>
              <vs-tab label="Overview" icon-pack="feather" icon="icon-home">
                <category-overview />
              </vs-tab>
              <vs-tab label="Products" icon-pack="feather" icon="icon-list">
                <category-products />
              </vs-tab>
              <vs-tab label="Meta & Desc" icon-pack="feather" icon="icon-settings">
                <category-meta-desc />
              </vs-tab>
            </vs-tabs>
          </template>
        </div>
      </div>
    </div>
    <is-loading v-if="isLoading" />
  </div>
</template>


<script>
import IsLoading from '@/components/IsLoading.vue'
import CategoryOverview from "@/components/category/CategoryOverview.vue"
import CategoryProducts from "@/components/category/CategoryProducts.vue"
import CategoryMetaDesc from "@/components/category/CategoryMetaDesc.vue"

export default{
  components: {
    IsLoading,
    CategoryOverview,
    CategoryProducts,
    CategoryMetaDesc
  },
  data() {
    return {
      isLoading: true,
    }
  },
  created(){
    this.$store.dispatch("categoryList/fetchCategory", this.$route.params.categoryId)
    .then((response) => {
      this.isLoading = false
      if(!response.data.success){
        this.$router.push({path: '/error-404'})
      }
    })
  },
}
</script>

<style lang="scss">
#faq-page {
    .faq-jumbotron-content {
        background-size:100%;
        background-repeat-y: no-repeat;
    }
    .faq-bg {
        background-color: #fff;
    }
}
</style>
