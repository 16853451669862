<template>
  <vx-card no-shadow>
    <form>
      <div class="vx-row">
        <div class="vx-col w-full sm:w-2/3 md:w-1/3 mb-base">
          <vx-card :subtitle="'Category Image'" class="card-reduce-top-pad">
              <img :src="imageHolder" alt="content-img" class="responsive rounded-lg">
          </vx-card>
          <div class="upload-img mt-3">
            <p class="text-sm mb-2">
                Dimension should be <code>500px</code> x <code>500px</code><br> Maximum Size <code> 500kb</code>
            </p>
            <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button size="small" @click="$refs.uploadImgInput.click()">Change Image</vs-button>
          </div>
        </div>

        <div class="vx-col md:w-2/3 w-full">
          <vs-input v-validate="{ required: true, regex: /^[a-zA-Z0-9 -&_]+$/}" label="Category Name *" placeholder="Category Name" name="name" v-model="data.name" class="mt-5 w-full" />
          <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>

          <vs-input v-validate="{ required: true, regex: /^[a-z0-9-&_]+$/}" label="Slug" name="slug" v-model="data.slug" class="mt-5 w-full" placeholder="Enter URL name" />
          <span class="text-danger text-sm" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>

          <div class="vx-row mt-8">
            <div class="vx-col w-1/2 mb-5 md:w-1/5 flex">
              <span class="mr-8">
                <span class="mr-4 block text-sm">Featured </span>
                <vs-switch v-model="data.is_featured">
                  <span slot="on">On</span>
                  <span slot="off">Off</span>
                </vs-switch>
              </span>
            </div>
            <div class="vx-col w-1/2 mb-5 md:w-1/5 flex">
              <span class="mr-8">
                <span class="mr-4 block text-sm">Toolbar</span>
                <vs-switch v-model="data.on_toolbar">
                  <span slot="on">On</span>
                  <span slot="off">Off</span>
                </vs-switch>
              </span>
            </div>
            <div class="vx-col w-1/2 mb-5 md:w-1/5 flex">
              <span class="mr-8">
                <span class="mr-4 block text-sm"> Active</span>
                <vs-switch v-model="data.is_active">
                  <span slot="on">On</span>
                  <span slot="off">Off</span>
                </vs-switch>
              </span>
            </div>
            <div class="vx-col w-1/2 mb-5 md:w-2/5 flex">
              <span>
                <span class="mr-4 block text-sm">Set Display Position</span>
                <div class="centerx">
                  <vs-input-number min="1" max="20" v-model="data.position"/>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button type="filled" size="small" @click.prevent="updateCategory" class="mt-2 block">Update</vs-button>
        <vs-button class="ml-4 mt-2" size="small" type="border" @click="fetchCategory()" color="success">Reset Data</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
export default {
  components:{
  },
  data() {
    return {
      imageHolder: "",
      newImage: "",
      description: "",
      data: {},
    }
  },
  computed: {
    category(){
      return this.$store.state.categoryList.category
    },
  },
  methods: {
    updateCategory() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          formData.append('image_file', this.newImage)
          this.$vs.loading({color: "#444", type: "sound"})
          this.$http.post(`/categories/${this.category.id}/update`,formData)
          .then((response)=> {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit('categoryList/UPDATE_CATEGORY', response.data.data)
            }
          })
        }
      })
    },
    patchCategory(){
      let loadedCategory = this.category
      this.data = {
        name: loadedCategory.name,
        slug: loadedCategory.slug,
        is_active: loadedCategory.is_active,
        is_featured: loadedCategory.is_featured,
        on_toolbar: loadedCategory.on_toolbar,
        parent_id: loadedCategory.parent_id,
        position: loadedCategory.position,
        price: loadedCategory.price,
      }
      this.imageHolder = this.absoluteUrl(loadedCategory.image)
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.imageHolder = e.target.result
        }
        this.newImage = input.target.files[0]
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  created(){
    this.patchCategory()
  },
}
</script>
